
import appStoreImage from '../img/app-store.png';
import googlePlayImage from '../img/google-play-badge.png';
import clock from '../img/clock.png';
import club from '../img/club.png';
import score from '../img/score.png';
import { useParams } from 'react-router-dom';
import apps from '../constants/apps';
import MailchimpSubscribe from "react-mailchimp-subscribe"

const url = "//gmail.us13.list-manage.com/subscribe/post?u=e27f36bff40fad613a518bd36&amp;id=530c478e58&amp;f_id=006c2de6f0";
const SimpleForm = () => <MailchimpSubscribe url={url}/>
export default function Home() {

  let keys = Object.keys(apps);


  return (
    <main style={{ backgroundColor: apps["home"].backgroundColor }}>

     <h1>Contact</h1>
      <p>Email me any time at <a href="mailto:i@gruman.co">i@gruman.co</a>.</p>

    </main>
  )
}