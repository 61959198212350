import React from 'react';

export default function App() {
  return (
    <header>
      <ul>
        <li><a href="/">Home</a></li>
        <li><a href="https://www.facebook.com/profile.php?id=61557147411426">Facebook</a></li>
        <li><a href="https://x.com/gruman">Twitter</a></li>
        <li><a href="https://www.youtube.com/channel/UCmu3_hJDjMcDOyFIWchHUQg">YouTube</a></li>
        <li><a href="/contact">Contact</a></li>
      </ul>
    </header>
  );
}
