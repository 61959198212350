
import { useParams } from 'react-router-dom';
import apps from '../constants/apps';

export default function Home() {

  let { slug } = useParams();
  if (!slug) {
    slug = "home"
  }

  console.log(apps[slug].links.android)

  return (
    <main>
      <h1>{apps[slug].title}</h1>
      <h2 style={{fontStyle: 'italic'}}>{apps[slug].subtitle}</h2>
      {
        slug &&
<>
        <div className="badges">
          <a href={apps[slug].links.ios}>
            <img className="badge" src={require('../img/app-store.png')} />
          </a>

          <a href={apps[slug].links.android}>
            <img className="badge" src={require('../img/google-play-badge.png')} />

          </a>
        </div> <img className="screenshot" src={apps[slug].screenshot} />
       
        </>
      }
      <div dangerouslySetInnerHTML={{ __html: apps[slug].description }} />
    </main>
  )
}