import { useState } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
export default function Home() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState(0)
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [page, setPage] = useState("clock")
  const sendMessage = async () => {
    if (!message || !email || !name) {
      alert("All fields are required thanks!")
      return;
    }
    const item = {
      subject: "Sponsorship email re: " + page,
      email: 'matthewgruman@gmail.com',
      body: name + "\n\n" + email + "\n\nOption: " + options +  "\n\nStart:" + startDate + "\n\nEnd: " + endDate + "\n\n" + message
    };
    setMessage('')
    setEmail('')
    setName('')
    setLoading(true);
    setTimeout(() => {
      setLoading(false)
    }, 2000)
    await axios.post('https://email-gruman.vercel.app', item);

  }

  const BackgammonClock = () => {
    return(
      <>
      <div className="sponsorship-offers">
      <div onClick={() => setOptions(0)} style={{backgroundColor: options === 0 && '#eee'}}>
      <h3>Option 1 ($25/week):</h3>
      <p>Interactive Banner on Settings screen.</p>
      </div>
      <div onClick={() => setOptions(1)} style={{backgroundColor: options === 1 && '#eee'}}>
      <h3>Option 2 ($50/week):</h3>
      <p>Banner on Settings screen with interactive modal.</p>
      </div>
      <div onClick={() => setOptions(2)} style={{backgroundColor: options === 2 && '#eee'}}>
      <h3>Option 3 ($100/week):</h3>
      <p>Option 2 + your non-interactive logo on the main buttons.</p>
      </div>
      </div>
      <h3>Statistics</h3>
      <ul>
        <li>First Google result for "backgammon clock ios"</li>
        <li>First Google result for "backgammon clock android"</li>
        <li>Second Google result for "backgammon clock"</li>
        <li>400 installs</li>
        <li>4 sessions per active device</li>
        <li>All of this in 3 months!</li>
      </ul></>
    )
  }
  const BackgammonScorekeeper = () => {
    return(
      <>
      <div className="sponsorship-offers">
      <div onClick={() => setOptions(0)} style={{backgroundColor: options === 0 && '#eee'}}>
      <h3>Option 1 ($25/week):</h3>
      <p>1 interactive banner on Home screen.</p>
      </div>
      <div onClick={() => setOptions(1)} style={{backgroundColor: options === 1 && '#eee'}}>
      <h3>Option 2 ($40/week):</h3>
      <p>2 interactive Banners on Home screen.</p>
      </div>
      <div onClick={() => setOptions(2)} style={{backgroundColor: options === 2 && '#eee'}}>
      <h3>Option 3 ($60/week):</h3>
      <p>Option 2 + your non-interactive logo on the main buttons.</p>
      </div>
      </div>
      <h3>Statistics</h3>
      <ul>
        <li>First Google result for "backgammon scorekeeper"</li>
        <li>100 installs</li>
        <li>3.22 sessions per active device</li>
      </ul></>
    )
  }
  const Chouette = () => {
    return(
      <>
      <div className="sponsorship-offers">
      <div onClick={() => setOptions(0)} style={{backgroundColor: options === 0 && '#eee'}}>
      <h3>Option 1 ($25/week):</h3>
      <p>1 interactive banner on all screen.</p>
      </div>
      </div>
      <h3>Statistics</h3>
      <ul>
        <li>First Google results for "chouette ios" and "chouette android"</li>
        <li>The only Backgammon Chouette App in either store</li>
        <li>2.18 sessions per active device</li>
      </ul></>
    )
  }
  const Cube = () => {
    return(
      <>
      <div className="sponsorship-offers">
      <div onClick={() => setOptions(0)} style={{backgroundColor: options === 0 && '#eee'}}>
      <h3>Option 1 ($20/week):</h3>
      <p>1 interactive banner on all screens.</p>
      </div>
      <div onClick={() => setOptions(1)} style={{backgroundColor: options === 1 && '#eee'}}>
      <h3>Option 2 ($30/week):</h3>
      <p>2 interactive Banners on all screens.</p>
      </div>
      </div></>
    )
  }
  const BackgammonDice = () => {
    return(
      <>
      <div className="sponsorship-offers">
      <div onClick={() => setOptions(0)} style={{backgroundColor: options === 0 && '#eee'}}>
      <h3>Option 1 ($20/week):</h3>
      <p>1 interactive banner on Home screen.</p>
      </div>
      <div onClick={() => setOptions(1)} style={{backgroundColor: options === 1 && '#eee'}}>
      <h3>Option 2 ($30/week):</h3>
      <p>2 interactive Banners on Home screen.</p>
      </div>
      <div onClick={() => setOptions(2)} style={{backgroundColor: options === 2 && '#eee'}}>
      <h3>Option 3 ($50/week):</h3>
      <p>Option 2 + your non-interactive logo on the main buttons.</p>
      </div>
      </div>
      <h3>Statistics</h3>
      <ul>
        <li>Third Google result for "backgammon dice roller"</li>
        <li>80 installs</li>
        <li>2 sessions per user</li>
      </ul></>
    )
  }
  
  return (
    <main>

      <h1>Sponsorship</h1>
      <ul className="sponsorship-menu">
        <li onClick={() => setPage("clock")} style={{color: page === 'clock' && '#fff', backgroundColor: page === 'clock' && '#222'}}>Backgammon Clock</li>
        <li onClick={() => setPage("scorekeeper")} style={{color: page === 'scorekeeper' && '#fff', backgroundColor: page === 'scorekeeper' && '#222'}}>Backgammon Scorekeeper</li>
        <li onClick={() => setPage("dice")} style={{color: page === 'dice' && '#fff', backgroundColor: page === 'dice' && '#222'}}>Backgammon Dice Roller</li>
        <li onClick={() => setPage("chouette")} style={{color: page === 'chouette' && '#fff', backgroundColor: page === 'chouette' && '#222'}}>Chouette</li>
        <li onClick={() => setPage("cube")} style={{color: page === 'cube' && '#fff', backgroundColor: page === 'cube' && '#222'}}>Backgammon Cube</li>

      </ul>
      {
        page === "clock" ?
        <BackgammonClock /> :
        page === 'scorekeeper' ?
        <BackgammonScorekeeper /> :
        page === "dice" ?
        <BackgammonDice /> :
        page === 'chouette' ?
        <Chouette /> :
        <Cube />
      }
      <div className="dates">
      <div>
        <h4>Start date</h4>
        <Calendar onChange={setStartDate} value={startDate} />
      </div>
      <div>
        <h4>End date</h4>
        <Calendar onChange={setEndDate} value={endDate} />
      </div>
      </div>
        <label>Name:</label>
        <input className="wide" type="text" value={name} onChange={e => setName(e.target.value)} />
        <label>Email:</label>
        <input className="wide" type="email" value={email} onChange={e => setEmail(e.target.value)} />
       <label>Message:</label>
       <textarea value={message} onChange={e => setMessage(e.target.value)} />
       <button className="btn" onClick={sendMessage}>{loading ? "Message sent" : "Send message"}</button>
      <h3>Notes</h3>
      <ul>
        <li>All prices in USD</li>
        <li>Availability is first-come first-served</li>
      </ul>
    </main>
  )
}