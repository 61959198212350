const apps = {
  home: {
    title: "Elo",
    header: "Home",
    subtitle: "Elo in your pocket",
    slug: "",
    description: `<p>Embark on a captivating journey into the world of backgammon with Elo Club, a sophisticated and feature-rich app designed to elevate your gaming experience. In Elo Club, we bring you the time-tested Elo rating system, renowned for its precision in measuring skill levels. Engage in strategic battles against friends and rivals, where every move counts, and the thrill of competition is at its peak.</p>
    
    <h3>Login and save to the cloud</h3>
    <p>Login with a new account or use your Backgammon Club account to save across different devices. Don't worry, it will always save to your phone so unless you delete the app you keep your stats forever.</p>
    
    <p>The Elo rating system, named after its creator Arpad Elo, assigns a numerical value to each player based on their performance. What makes Elo Club exceptional is its seamless integration of this system, ensuring that each match is a true test of skill. Whether you're a seasoned backgammon enthusiast or a newcomer to the game, Elo Club adapts to your level, providing a fair and enjoyable experience for all.</p>
    
    <p>Challenge your friends to intense backgammon tournaments, or face off against skilled rivals from around the world. Elo Club is not just a game; it's your personal backgammon arena where you can hone your strategic prowess. The Elo rating system adds a layer of depth and authenticity to the gameplay, making each victory more satisfying and each defeat a valuable learning experience.</p>
    
    <p>But Elo Club offers more than just thrilling matches. With our player management features, you can easily add, edit, and organize your roster of players. Keep track of wins, losses, and Elo ratings in a user-friendly interface. The leaderboard showcases the top performers, adding a competitive edge to the social aspect of the game.</p>
    
    <p>What sets Elo Club apart is its innovative feature that allows you to export your gameplay data as JSON. Delve into the intricacies of your performance, analyze your strategies, and gain valuable insights to enhance your skills. This export functionality is a game-changer for those who seek a deeper understanding of their gameplay and want to track their progress over time.</p>
    
    <p>Elo Club isn't just a backgammon app; it's your pocket-sized mentor, helping you improve and enjoy the game even more. Immerse yourself in the world of backgammon, where strategy meets camaraderie, and where every match is an opportunity to grow. Download Elo Club now and experience backgammon like never before – strategic, social, and personalized to your journey.</p>`,
    icon: require('../img/icons/cube.png'),
    screenshot: require('../img/elo.png'),
    backgroundColor: '#fff',
    textColor: '#222',
    links: {
      android: "https://apps.apple.com/gb/app/elo-club/id6478157554?platform=iphone",
      ios: "https://apps.apple.com/gb/app/elo-club/id6478157554?platform=iphone",
      web: ""
    }
  },
  clock: {
    title: "Backgammon Clock",
    header: "Clock",
    subtitle: "The only clock *made* for Backgammon.",
    slug: "clock",
    description: `<p>Elevate your backgammon experience with Backgammon Clock, the ultimate game timer and score tracker designed specifically for backgammon enthusiasts.</p>
    
    <h3>Key Features:
    
    <h3>Dice Mode:</h3>
    <p>Let luck take its course! Activate the dice mode and let the app roll for you, adding an extra layer of excitement to your games.</p>
    
    <h3>Score Tracking:</h3>
    <p>Keep a close eye on the competition by easily tapping the score during the game. Stay informed about who's leading in real-time.</p>
    
    <h3>Adjustable Delay:</h3>
    <p>Tailor the clock to match your pace. Fine-tune the delay settings to suit your playing style and strategic preferences.</p>
    
    <h3>Customizable Colors:</h3>
    <p>Personalize your experience! Choose your favorite colors to make the app uniquely yours. Enhance visibility with font contrast correction using the HSB model.</p>
    
    <p>Whether you're a seasoned player or just starting, Backgammon Clock is your indispensable companion for immersive and strategic backgammon matches. Download now and elevate your gaming experience!</p>`,
    icon: require('../img/icons/clock.png'),
    screenshot: require('../img/clock.png'),
    backgroundColor: '#7A1000',
    textColor: '#fff',
    links: {
      android: "https://play.google.com/store/apps/details?id=co.gruman.chessclock",
      ios: "https://apps.apple.com/gb/app/backgammon-clock/id6475617523",
      web: ""
    },
    sponsorship: true
  },
  scorekeeper: {
    title: "Backgammon Scorekeeper",
    header: "Score",
    subtitle: "Optimized for iPad.",
    slug: "scorekeeper",
    description: `<p>Whether you're a seasoned backgammon pro or enjoy various tabletop games, this app is your go-to companion for keeping scores effortlessly.</p>
    <h3>Key Features:</h3>
    
    <h4>Fully Customizable Colors:</h4>
    <p>Personalize your gaming experience by choosing from a spectrum of vibrant colors to match your style and preferences. Make your scorekeeping visually appealing and tailor it to the theme of your game night!</p>
    
    <h4>Easy Undo:</h4>
    <p>Mistakes happen, but with our user-friendly interface, correcting them is a breeze! The easy undo feature allows you to effortlessly backtrack and adjust scores, ensuring fair play and a stress-free gaming atmosphere.</p>
    
    <h4>Multi-Game Support:</h4>
    <p>Beyond backgammon, our app supports a variety of games! Whether you're playing classic board games or engaging in custom tabletop adventures, the Backgammon Score Keeper adapts to your needs.</p>
    
    <h4>User-Friendly Interface:</h4>
    <p>Navigate the app with ease, thanks to its intuitive design. Enjoy a seamless scorekeeping experience that doesn't distract you from the thrill of the game.</p>
    
    <h4>Multiplayer Friendly:</h4>
    <p>Perfect for both one-on-one duels and group play, this app ensures that everyone stays in the loop with accurate and up-to-date scores. No more disputes – focus on the game and let the Backgammon Score Keeper handle the rest!</p>
    
    <p>Download the Backgammon Score Keeper now and elevate your gaming sessions to new heights. Embrace the power of customization, easy undo functionality, and streamlined score tracking. It's time to focus on the game, not the score!</p>`,
    icon: require('../img/icons/scorekeeper.png'),
    screenshot: require('../img/score.png'),
    backgroundColor: '#9A1860',
    textColor: '#fff',
    links: {
      android: "https://play.google.com/store/apps/details?id=co.gruman.scorekeeper",
      ios: "https://apps.apple.com/gb/app/backgammon-score-keeper/id6476737735",
      web: ""
    },
    sponsorship: true
  },

  dice: {
    title: "Backgammon Dice",
    header: "Dice",
    subtitle: "Optimized for iPad.",
    slug: "dice",
    description: `<p>Welcome to Backgammon Dice Roller, the perfect companion for visually impaired users who love playing backgammon. Our dice rolling app prioritizes accessibility with large dice and customizable colors for optimal contrast.</p>

    <h3>Key Features:</h3>
    
    <h4>Large Dice:</h4> 
    <p>Enjoy easy visibility with large, clear dice that are perfect for users with visual difficulties.</p>
    <h4>Customizable Colors:</h4>
    <p>Tailor the app to your preferences by changing dice and background colors for enhanced contrast.</p>
    <h4>20+ Themes:</h4>
    <p>Personalize your dice rolling experience with a variety of preloaded themes to suit your style.</p>
    <h4>Smooth Animations:</h4>
    <p>Watch the dice roll in style with smooth and visually appealing animations.</p>
    <h3>Themes for Everyone:</h3>
    <p>Explore a collection of over 20 themes, ranging from classic designs to vibrant colors. Find the perfect theme that matches your mood and preferences.</p>
    
   <h3>Roll with Confidence:</h3>
    <p>With Backgammon Dice Roller, you can confidently roll the dice and focus on your game without worrying about readability issues. Our app is designed to make backgammon more enjoyable for everyone.</p>
    
    <h3>Accessibility Matters:</h3>
    <p>We believe in inclusivity. Backgammon Dice Roller is designed to provide a delightful experience for users with visual difficulties. Your enjoyment is our priority.</p>
    
    <h3>Compatibility:</h3>
    <p>Compatible with all iOS devices. Play backgammon with ease on your iPhone or iPad.</p>
    
   <p>Download Backgammon Dice Roller now and enhance your gaming experience with accessible, customizable dice!</p>`,
    icon: require('../img/icons/dice.png'),
    screenshot: require('../img/dice.png'),
    backgroundColor: '#0376BB',
    textColor: '#fff',
    links: {
      android: "https://play.google.com/store/apps/details?id=backgammondice.gruman.co",
      ios: "https://apps.apple.com/gb/app/backgammon-dice-roller/id6477749225",
      web: ""
    },
    sponsorship: true
  },
  chouette: {
    title: "Chouette",
    header: "Chouette",
    subtitle: "Let me do the math.",
    slug: "chouette",
    description: `<p>Elevate your backgammon chouette experience with Chouette, the ultimate app designed to streamline and enhance your gameplay. With Chouette, managing a backgammon chouette has never been easier – simply enter players' names, and let the app handle the rest!</p>

    <p>Key Features:</p>
    
    <p>Effortless Setup: Say goodbye to manual calculations. Chouette allows you to enter players' names with ease, eliminating the hassle of keeping track of scores and bets.</p>
    <p>Dynamic Cash Outs: Take control of the game like never before. Cash out players at any time during the chouette session, ensuring flexibility and convenience in managing your backgammon games.</p>
    <p>On-the-Fly Player Additions: Need to introduce new players to the chouette? No problem! Chouette lets you seamlessly add new participants whenever you desire, ensuring a dynamic and inclusive gaming experience.</p>
    <p>Automated Math: The final cash-out screen simplifies the entire process. Chouette automatically performs all necessary calculations, saving you time and ensuring accurate payouts for each player.</p>
    <p>Intuitive Interface: Chouette boasts a user-friendly interface that makes navigating the app a breeze. Enjoy a visually appealing and efficient platform for your backgammon chouette games.</p>
    <p>Download Chouette now and revolutionize the way you play backgammon chouette. Let the app handle the logistics, so you can focus on the excitement of the game!</p>`,
    icon: require('../img/icons/chouette.png'),
    screenshot: require('../img/chouette.png'),
    backgroundColor: '#D2DC6D',
    textColor: '#222',
    links: {
      android: "https://play.google.com/store/apps/details?id=co.gruman.chouette",
      ios: "https://apps.apple.com/gb/app/chouette-app/id6477782918",
      web: "https://play.google.com/store/apps/details?id=co.gruman.chouette&hl=en_CA&gl=US"
    },
    sponsorship: true
  },
  cube: {
    title: "Backgammon Cube",
    header: "Backgammon Cube",
    subtitle: "Have the biggest cube at the table.",
    slug: "cube",
    description: `<>Dive into the ultimate backgammon experience with our feature-packed app! Play like a pro with 20 stunning themes to match your style. Whether you're a seasoned player or just starting out, Backgammon Cube offers accessibility-focused gameplay for everyone.</p>
    <p>!!! Optimized for iPad !!!</p>
    <p>Customize your gaming experience with optional sound mode, immersing yourself in every move.</p>
    <p>Beavers for added fun!</p>
    <p>Download Backgammon Cube now and experience the thrill of the board like never before!</p>`,
    icon: require('../img/icons/chouette.png'),
    screenshot: require('../img/chouette.png'),
    backgroundColor: '#D2DC6D',
    textColor: '#222',
    links: {
      android: "https://play.google.com/store/apps/details?id=co.gruman.chouette",
      ios: "https://apps.apple.com/gb/app/chouette-app/id6477782918",
      web: "https://play.google.com/store/apps/details?id=co.gruman.chouette&hl=en_CA&gl=US"
    },
    sponsorship: true
  },
  roundrobin: {
    title: "Round Robin",
    header: "Robin",
    subtitle: "The Colourful and Easy way to play a Round Robin",
    slug: "roundrobin",
    description: `<h3>The colourful and easy way to play a Round Robin!</h3>
    <p>Welcome to Round Robin, your go-to app for effortlessly organizing and managing round-robin style tournaments! Whether you're planning a friendly game night or coordinating a competitive sports event, Round Robin is your perfect tournament companion. Simplify the process of creating matches, enjoy vibrant player displays, and experience the convenience of saving your tournaments for future reference.</p>
    <h3>Key Features:</h3>
    <p>Effortless Tournament Creation:</p>
    <p>Streamline the process of setting up round-robin tournaments. Enter participant names, and let Round Robin automatically generate the matches.</p>
    <p>Colorful Player Representation:</p>
    <p>Each player is uniquely highlighted with a distinct color, enhancing visibility and making it easy to track their progress throughout the tournament.</p>
    <p>User-Friendly Interface:</p>
    <p>Intuitive and easy-to-use interface, ensuring that users of all ages and experience levels can quickly grasp the tournament creation and management process.</p>
    <h3>Flexible Tournament Management:</h3>
    <p>Versatility Across Activities:</p>
    <p>Perfect for a wide range of activities, including board games, sports events, and more. Round Robin adapts to your needs, making it an indispensable tool for various occasions.</p>
    <p>Organize With Confidence:</p>
    <p>With Round Robin, you can confidently organize and execute tournaments without the hassle of manual planning. Let the app do the work, while you enjoy the excitement of the competition.</p>
    <p>Time-Saving Automation:</p>
    <p>Save valuable time with Round Robin's automated tournament creation. No need to spend hours planning matches; the app handles it efficiently.</p>
    <p>Consistent Updates:</p>
    <p>Expect regular updates to enhance functionality and introduce new features. Our commitment is to provide users with the best experience and meet evolving needs.</p>
    <p>Download Round Robin now and elevate your tournament management experience. Whether you're a sports enthusiast, board game aficionado, or event organizer, Round Robin is your key to seamless and enjoyable tournaments!</p>`,
    icon: "",
    screenshot: "",
    backgroundColor: '#D2DC6D',
    textColor: '#222',
    links: {
      android: "https://play.google.com/store/apps/details?id=co.gruman.roundrobinapp",
      ios: "https://apps.apple.com/gb/app/round-robin/id6479245757",
      web: "https://play.google.com/store/apps/details?id=co.gruman.chouette&hl=en_CA&gl=US"
    }
  },
  club: {
    title: "Backgammon Club",
    header: "Club",
    subtitle: "Make every game a tournament",
    slug: "club",
    description: `<h3>Welcome to Backgammon Club</h3>
    <p>HUGE UPDATE: Share your Tournaments and send them to your players with your own personal webpage.</p>

    <p>Welcome to Backgammon Club, the ultimate game-tracking app that transforms your backgammon experience into an engaging long-term, long-distance tournament. Connect with friends, track your games, and dominate the leaderboard!</p>

<h3>Key Features:</h3>

<h3>Game Tracking:</h3>
<p>Effortlessly record wins and losses against friends and opponents. Keep a detailed history of your matches and stay on top of your game.</p>

<h3>Elo Calculator:</h3>
<p>Climb the Elo ranks! Our app calculates your Elo score, providing a dynamic measure of your skill level as you compete in the long-term tournament.</p>

<h3>Online Connectivity:</h3>
<p>Stay connected to the action! Backgammon Club links to the online site at backgammonclub.club, allowing you to participate in tournaments and compete with players from around the world.</p>

<h3>Player Management: </h3>
<p>Build your backgammon community! Add friends and opponents to your club, track their progress, and challenge them to matches anytime, anywhere.</p>

<h3>Leaderboard:</h3>
<p>Ascend the ranks and dominate the leaderboard. Showcase your backgammon skills and see how you compare to players globally.</p>

<p>Backgammon Club is not just a game; it's a vibrant backgammon community where every move counts. Join the club, track your progress, and embark on a journey to become the backgammon champion!</p>
    `,
    icon: require('../img/icons/club.png'),
    screenshot: require('../img/club.png'),
    backgroundColor: '#6DDCDC',
    textColor: '#222',
    links: {
      android: "https://play.google.com/store/apps/details?id=club.backgammon.club",
      ios: "https://apps.apple.com/gb/app/backgammon-club/id6475646369",
      web: ""
    }
  },
}

export default apps;