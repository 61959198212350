import React, { useState, useEffect } from 'react';
import './App.css';

import Home from './pages/Home';
import Pages from './pages/Pages';
import Tournaments from './pages/Tournaments';
import Topbar from './components/Topbar'
import Sponsorship from './pages/Sponsorship'
import Contact from './pages/Contact';
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
      <BrowserRouter>

          {/* <Navbar /> */}
        <div className="container">
          <Topbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sponsorship" element={<Sponsorship />} />
            <Route path="/:slug" element={<Pages />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          </div>
      </BrowserRouter>
  );
}

export default App;
