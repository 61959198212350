
import appStoreImage from '../img/app-store.png';
import googlePlayImage from '../img/google-play-badge.png';
import clock from '../img/clock.png';
import club from '../img/club.png';
import score from '../img/score.png';
import { useParams } from 'react-router-dom';
import apps from '../constants/apps';

export default function Home() {

  let keys = Object.keys(apps);


  return (
    <main>
      <div className="intro">
        <img src={require('../img/backgammon-board.png')} className="logo" />
        <h1>Backgammon Club</h1>
        <h2>The Home of IRL Backgammon Apps by <a href="https://matthewgruman.com">Matthew Gruman</a></h2>
      </div>
      <div className="grid-container">
        {
          keys.map(item => {
            if (apps[item].slug) {
              return (
                <div className="item">
                  {
                    apps[item].sponsorship &&
                    <a href="/sponsorship" style={{ textDecoration: 'none' }}>
                      <div className="sponsorship">
                        Sponsor this app!
                      </div>
                    </a>
                  }
                  <a href={"/" + apps[item].slug}>
                    <h3>{apps[item].title}</h3>
                  </a>
                  <p style={{ fontStyle: 'italic' }}>{apps[item].subtitle}</p>
                  <p><a href={apps[item].links.ios}>Download on iOS </a></p>
                  <p><a href={apps[item].links.android}>Download on Android </a></p>

                </div>
              )
            }
          })
        }
      </div>
      <h2>Need support?</h2>
      <p>Email me any time at <a href="mailto:i@gruman.co">i@gruman.co</a>.</p>
    </main>
  )
}